import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { updateApp } from '../../../api/appsApi'

import { TextareaWithAutosize } from '../../TextareaWithAutosize'
import { DraftsContext, DraftType } from '../../utils/DraftsContext'
import styles from './DraftPreview.module.scss'

interface Props {
    draft: DraftType
}

export const DraftPreview: React.FC<Props> = ({ draft }) => {
    const { deleteDraft, updateDraft } = useContext(DraftsContext)
    const { id, appTitle, appIcon } = draft
    const navigate = useNavigate()
    const draftPath = `${id}`

    const handleChangeName = (appTitle: string) => {
        updateApp({ ...draft, appTitle }).then(() => updateDraft({ ...draft, appTitle }))
    }

    const handleDeleteDraft = () => {
        deleteDraft(id)
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.preview}
                onClick={() => navigate(draftPath)}
                style={{ backgroundImage: `url(${appIcon})` }}
            >
                <div className={styles.controlButtons} onClick={(e) => e.stopPropagation()}>
                    <img className={styles.icon} src="/images/removeDraftIcon.png" alt="" onClick={handleDeleteDraft} />
                    <img className={styles.icon} src="/images/copyDraftIcon.png" alt="" />
                    <Link to={draftPath}>
                        <img className={styles.icon} src="/images/editDraftIcon.png" alt="" />
                    </Link>
                </div>
            </div>

            <div className={styles.title}>
                <TextareaWithAutosize initialValue={appTitle} setInitialValue={handleChangeName} placeholder="" />
            </div>
        </div>
    )
}
