import React, { ReactNode, createContext, useState, useEffect } from 'react'
import { createApp, getApps, removeApp } from '../../api/appsApi'
import { replaceNullFields } from '../../utils/format'

export interface CountryInterface {
	code: string
	name: string
}

export interface TargetingGenderInterface {
	value: string
	label: string
}

export interface TargetingAgeInterface {
	id: number
	name: string
}

export interface CaseStudyInterface {
	value: string
	label: string
}

export interface SegmentInterface {
	value: string
	label: string
}

export interface ImageInterface {
	name: string
	url: string
}

export interface ManualSegmentInterface {
	name: string
	file: ImageInterface
}

export interface AppVerticalInterface {
	id: number
	name: string
}

export interface SimilarAppInterface {
	appName: string
	appIcon: string
}

export interface CreativeInterface {
	id: number
	url: string
}

export type DraftType = {
	id: number
	bundleId: string
	appTitle: string
	appIcon: string
	creativeId: number
	creativesInfo: CreativeInterface[]
	companyName: string
	companyLogo: ImageInterface
	genderId: number
	ageIds: number[]
	country: string[]
	similarAppsInfo: SimilarAppInterface[]
	similarApps: SimilarAppInterface[]
	manualSimilarAppsBundleId: string
	manualSimilarAppBundleId: string
	firstCaseStudyId: string
	secondCaseStudyId: string
	firstSegmentId: number
	secondSegmentId: number
	thirdSegmentId: number
	manualFirstSegment: ManualSegmentInterface
	manualSecondSegment: ManualSegmentInterface
	manualThirdSegment: ManualSegmentInterface
	rtCaseStudyId: string
	craftedBy: string
	verticalId: number
	manualVertical: string
}

export const defaultDraft: DraftType = {
	id: 0,
	bundleId: '',
	appTitle: '',
	appIcon: '',
	creativeId: 0,
	creativesInfo: [],
	companyName: '',
	companyLogo: {
		name: '',
		url: '',
	},
	genderId: -1,
	ageIds: [],
	country: [],
	similarAppsInfo: [],
	similarApps: [],
	manualSimilarAppsBundleId: '',
	manualSimilarAppBundleId: '',
	firstCaseStudyId: '',
	secondCaseStudyId: '',
	firstSegmentId: -1,
	secondSegmentId: -1,
	thirdSegmentId: -1,
	manualFirstSegment: { name: '', file: { name: '', url: '' } },
	manualSecondSegment: { name: '', file: { name: '', url: '' } },
	manualThirdSegment: { name: '', file: { name: '', url: '' } },
	rtCaseStudyId: '',
	craftedBy: '',
	verticalId: -1,
	manualVertical: '',
}

interface DraftsContextInterface {
	drafts: DraftType[]
	setApps: (apps: DraftType[]) => void
	createDraft: () => void
	readDraft: (index: number) => DraftType | undefined
	updateDraft: (updatedDraft: DraftType) => void
	deleteDraft: (index: number) => Promise<any>
}

export const DraftsContext = createContext<DraftsContextInterface>({
	drafts: [],
	setApps: () => {},
	createDraft: () => {},
	readDraft: () => ({ ...defaultDraft }),
	updateDraft: () => {},
	deleteDraft: () => Promise.resolve({}),
})

interface Props {
	children: ReactNode
}

export const DraftsContextProvider: React.FC<Props> = ({ children }) => {
	const [drafts, setDrafts] = useState<DraftType[]>([])

	useEffect(() => {
		getApps().then((data) => {
			const appsWithoutNull = data.map((app) => replaceNullFields(app))
			setDrafts(appsWithoutNull.reverse())
		})
	}, [])

	const createDraft: DraftsContextInterface['createDraft'] = () => {
		createApp().then((id) => {
			setDrafts((current) => [{ ...defaultDraft, id }, ...current])
		})
	}

	const setApps: DraftsContextInterface['setApps'] = (apps) => {
		setDrafts(apps)
	}

	const readDraft: DraftsContextInterface['readDraft'] = (index) => drafts.find((draft) => draft.id === index)

	const updateDraft: DraftsContextInterface['updateDraft'] = (updatedDraft) => {
		setDrafts((current) => current.map((draft) => (updatedDraft.id === draft.id ? updatedDraft : draft)))
	}

	const deleteDraft: DraftsContextInterface['deleteDraft'] = (index) => {
		return removeApp(index).then(() => {
			setDrafts((current) => current.filter((draft) => draft.id !== index))
		})
	}

	return (
		<DraftsContext.Provider
			value={{
				drafts,
				setApps,
				createDraft,
				readDraft,
				updateDraft,
				deleteDraft,
			}}
		>
			{children}
		</DraftsContext.Provider>
	)
}
