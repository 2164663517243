import React, { ChangeEvent, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styles from './TextareaWithAutosize.module.scss'

interface Props {
    initialValue: string
    setInitialValue: (value: string) => void
    placeholder: string
}

export const TextareaWithAutosize: React.FC<Props> = ({ initialValue, setInitialValue, placeholder }) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const [value, setValue] = useState(initialValue ? initialValue : placeholder)

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (['Enter', 'Escape'].includes(e.key) && textareaRef.current) {
            textareaRef.current.blur()
        }
    }

    const handleBlur = () => {
        if (!value) {
            setInitialValue(placeholder)
            setValue(placeholder)
        } else if (value !== initialValue) {
            setInitialValue(value)
        }
    }

    return (
        <TextareaAutosize
            ref={textareaRef}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            className={styles.textarea}
            spellCheck={false}
            maxRows={2}
            cacheMeasurements
        />
    )
}
