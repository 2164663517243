import React from 'react'
import Select from 'react-select'

import { OptionType } from './utils/inputTypes'
import { DraftType } from '../../utils/DraftsContext'
import styles from './Input.module.scss'
import { dropDownStyles } from './utils/dropDownStyles'

interface Props {
    name: keyof DraftType
    title: string
    placeholder: string
    options: OptionType[]
    value: OptionType[]
    setValue: (name: keyof DraftType, value: string) => void
    formatOption?: (option: OptionType) => JSX.Element
    limit?: number
}

export const MultiDropDownInput: React.FC<Props> = (props) => {
    const { name, title, placeholder, options, value, setValue, formatOption, limit } = props

    const handleSelect = (selectedOptions: any) => {
        setValue(
            name,
            selectedOptions.map((option: any) => option.value)
        )
    }

    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <Select
                defaultValue={value}
                options={options}
                onChange={handleSelect}
                placeholder={placeholder}
                styles={dropDownStyles}
                formatOptionLabel={formatOption}
                isOptionDisabled={() => (limit ? value.length >= limit : false)}
                closeMenuOnSelect={false}
                maxMenuHeight={200}
                isClearable={false}
                isMulti
            />
        </div>
    )
}
