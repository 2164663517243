import React from 'react'
import styles from './CheckboxInput.module.scss'

interface Props {
    checked: boolean
    onChange: () => void
}

export const CheckboxInput: React.FC<Props> = ({ checked, onChange }) => {
    return (
        <label className={styles.container}>
            <input className={styles.checkbox} type="checkbox" checked={checked} onChange={onChange} />
            <span className={styles.checkmark} />
        </label>
    )
}
