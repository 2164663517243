import { OptionType } from '../components/DraftForm/Inputs/utils/inputTypes'
import { defaultDraft, DraftType, TargetingAgeInterface } from '../components/utils/DraftsContext'

export const getCountryFlag = (code: string, size: number) => (
    <img src={`https://hatscripts.github.io/circle-flags/flags/${code}.svg`} width={size} alt={code} key={code} />
)

export const formatCountryOption = ({ value, label }: OptionType) => (
    <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
        {getCountryFlag(`${value}`, 16)}
        <span style={{ paddingTop: '2px' }}>{label}</span>
    </div>
)

export const joinAgeInterals = (ageInterals: TargetingAgeInterface[]) => {
    const ages: string[] = []
    let joinedAgeIntervals = ''

    ageInterals.forEach((item) => {
        if (item.name.includes('-')) {
            ages.push(...item.name.split('-'))
        } else if (item.name.includes('+')) {
            joinedAgeIntervals = `${Math.min(...ages.map((item) => +item))}+`
        }
    })

    const ageValuesNumber = ages.map((item) => +item)

    if (!joinedAgeIntervals && ageValuesNumber.length) {
        joinedAgeIntervals = `${Math.min(...ageValuesNumber)}-${Math.max(...ageValuesNumber)}`
    }

    return joinedAgeIntervals
}

export const replaceNullFields = (app: DraftType) => {
    const map = new Map()

    for (let [key, value] of Object.entries(app)) {
        if (value === null) {
            map.set(key, defaultDraft[key as keyof DraftType])
        } else {
            map.set(key, value)
        }
    }

    return Object.fromEntries(map) as DraftType
}
