import { observer } from 'mobx-react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { DraftsContextProvider } from './components/utils/DraftsContext'
import { Layout } from './components/Layout'
import { Drafts } from './pages/Drafts'
import { Draft } from './pages/Draft'
import { Login } from './pages/Login'
import { routes } from './utils/routes'
import { loginStore } from './store/LoginStore'
import { FormInfoContextProvider } from './components/utils/FormInfoContext'

export const App = observer(() => {
    const isAuthorized = loginStore.isAuthorized

    return (
        <DraftsContextProvider>
            <FormInfoContextProvider>
                {isAuthorized ? (
                    <Layout>
                        <Routes>
                            <Route path={routes.drafts} element={<Drafts />} />
                            <Route path={routes.draft} element={<Draft />} />
                            <Route path="*" element={<Navigate to={routes.drafts} replace />} />
                        </Routes>
                    </Layout>
                ) : (
                    <Routes>
                        <Route path={routes.login} element={<Login />} />
                        <Route path="*" element={<Navigate to={routes.login} replace />} />
                    </Routes>
                )}
            </FormInfoContextProvider>
        </DraftsContextProvider>
    )
})
