import React, { RefObject, useContext } from 'react'
import { FirstTemplatePage } from './FirstTemplatePage/FirstTemplatePage'
import { SecondTemplatePage } from './SecondTemplatePage'

import styles from './PDFTemplate.module.scss'
import { DraftType } from '../utils/DraftsContext'
import { defaultCaseStudy, defaultRtSegment, FormInfoContext } from '../utils/FormInfoContext'

interface Props {
	currentRef?: RefObject<HTMLDivElement>
	draft: DraftType
}

export const PDFTemplate: React.FC<Props> = ({ currentRef, draft }) => {
	const { uaCases, rtCases, rtSegments, countries, genders, ages, appVerticals } = useContext(FormInfoContext)
	const {
		creativeId,
		companyLogo,
		genderId,
		ageIds,
		country,
		companyName,
		craftedBy,
		manualVertical,
		verticalId,
		firstCaseStudyId,
		secondCaseStudyId,
		rtCaseStudyId,
		appIcon,
		appTitle,
		firstSegmentId,
		secondSegmentId,
		thirdSegmentId,
		manualFirstSegment,
		manualSecondSegment,
		manualThirdSegment,
		similarApps,
	} = draft

	const firstPageData = {
		creative:
			creativeId === 0
				? '/images/PDFTemplate/FirstTemplatePage/defaultPhoneScreen.svg'
				: draft.creativesInfo.filter(({ id }) => id === creativeId)[0]?.url,
		appTitle,
		appIcon,
		companyLogo,
		targetingGender: genders.filter(({ id }) => id === +genderId)[0]?.name,
		targetingAge: ages.filter(({ id }) => ageIds.includes(id)),
		countries: countries.filter(({ code }) => country.includes(code)),
		companyName,
		craftedBy,
		similarApps,
		caseStudies: {
			case1: uaCases.find(({ _id }) => _id === firstCaseStudyId) ?? defaultCaseStudy,
			case2: uaCases.find(({ _id }) => _id === secondCaseStudyId) ?? defaultCaseStudy,
		},
	}

	const secondPageData = {
		companyLogo,
		craftedBy,
		manualVertical,
		appVertical: appVerticals.filter(({ id }) => id === verticalId)[0],
		appIcon,
		rtManualSegments: {
			rtManualSegment1: manualFirstSegment,
			rtManualSegment2: manualSecondSegment,
			rtManualSegment3: manualThirdSegment,
		},
		rtSegments: {
			segment1: rtSegments.find(({ id }) => id === firstSegmentId) ?? defaultRtSegment,
			segment2: rtSegments.find(({ id }) => id === secondSegmentId) ?? defaultRtSegment,
			segment3: rtSegments.find(({ id }) => id === thirdSegmentId) ?? defaultRtSegment,
		},

		rtCaseStudy: rtCases.filter(({ _id }) => _id === rtCaseStudyId)[0] ?? defaultCaseStudy,
	}

	return (
		<div className={styles.container} ref={currentRef}>
			<FirstTemplatePage data={firstPageData} />
			<SecondTemplatePage data={secondPageData} />
		</div>
	)
}
