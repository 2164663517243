import React from 'react'
import { BlurBlock } from '../../BlurBlock'
import { CaseStudiesCard } from './CaseStudiesCard'
import { CreativeFormatsCard } from './CreativeFormatsCard'
import { DspCard } from './DspCard'
import { TrafficSourcesCard } from './TrafficSourcesCard'
import styles from './FirstTemplatePage.module.scss'
import { CountryInterface, ImageInterface, SimilarAppInterface, TargetingAgeInterface } from '../../utils/DraftsContext'
import { CaseStudyType } from '../../utils/FormInfoContext'
import { joinAgeInterals } from '../../../utils/format'

interface Props {
	data: {
		creative: string
		appTitle: string
		appIcon: string
		companyLogo: ImageInterface
		targetingGender: string
		targetingAge: TargetingAgeInterface[]
		countries: CountryInterface[]
		companyName: string
		similarApps: SimilarAppInterface[]
		caseStudies: {
			case1: CaseStudyType
			case2: CaseStudyType
		}
	}
}

export const FirstTemplatePage: React.FC<Props> = ({ data }) => {
	const {
		creative,
		appTitle,
		appIcon,
		targetingGender,
		targetingAge,
		countries,
		companyName,
		caseStudies,
		companyLogo,
		similarApps,
	} = data

	let joinedTargetingAge = joinAgeInterals(targetingAge)

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src="/images/logo.svg" alt="" />
				<img src="/images/PDFTemplate/crossIcon.svg" alt="" />
				<img src={companyLogo.url} alt="" />
			</div>

			<div className={styles.main}>
				<div className={styles.dsp}>
					<h1 className={styles.mainTitle}>Programmatic DSP for Mobile User Acquisition and Retargeting.</h1>
					<DspCard
						similarApps={similarApps}
						appTitle={appTitle}
						appIcon={appIcon}
						gender={targetingGender}
						age={joinedTargetingAge}
						countries={countries}
						company={companyName}
					/>
				</div>

				<div className={styles.trafficSources}>
					<h2 className={styles.secondaryTitle}>Our Traffic Sources</h2>
					<img src="/images/PDFTemplate/FirstTemplatePage/trafficSources.png" alt="" />
					<TrafficSourcesCard />
				</div>

				<div className={styles.formatsAndCases}>
					<div className={styles.creativeFormats}>
						<h2 className={styles.secondaryTitle}>Available Creative Formats</h2>
						<CreativeFormatsCard creative={creative} />
					</div>

					<div className={styles.caseStudies}>
						<CaseStudiesCard data={caseStudies} />
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.social}>
					<img src="/images/PDFTemplate/globalIcon.svg" alt="" /> persona.ly
				</div>

				<div className={styles.social}>
					<img src="/images/PDFTemplate/emailIcon.svg" alt="" /> info@persona.ly
				</div>
			</div>

			<BlurBlock
				top={5}
				left={5}
				background="linear-gradient(180deg, rgba(206, 156, 255, 0.3) 0%, rgba(69, 224, 252, 0.3) 100%)"
				transform="matrix(0, -1, -1, 0, 0, 0)"
				opacity={1}
			/>

			<BlurBlock
				top={10}
				right={10}
				background="linear-gradient(180deg, #FF6262 0%, #FBB251 100%)"
				opacity={0.2}
			/>
			<BlurBlock top={-10} right={-15} background="#ffffff" opacity={1} blur={60} />
		</div>
	)
}
