import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './BackButton.module.scss'

interface Props {
    onBack?: () => void
}

export const BackButton: React.FC<Props> = ({ onBack }) => {
    const navigate = useNavigate()

    const handleClickBack = () => {
        onBack ? onBack() : navigate(-1)
    }

    return (
        <button className={styles.link} onClick={handleClickBack}>
            <img src='/images/backArrowInCircle.png' alt='' />
            Back
        </button>
    )
}
