// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppSearch_container__kiYJV {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.AppSearch_container__kiYJV .AppSearch_title__bBHLV {\n  font-weight: 500;\n  font-size: 14px;\n  color: #161626;\n}\n.AppSearch_container__kiYJV .AppSearch_input__KAKTU {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 15px;\n  width: 300px;\n  font-size: 15px;\n  color: #161626;\n}\n.AppSearch_container__kiYJV .AppSearch_input__KAKTU::placeholder {\n  color: rgba(22, 22, 38, 0.3);\n}", "",{"version":3,"sources":["webpack://./src/components/AppSearch/AppSearch.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AACR;AAEI;EACI,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AAAR;AAEQ;EACI,4BAAA;AAAZ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n\n    .title {\n        font-weight: 500;\n        font-size: 14px;\n        color: #161626;\n    }\n\n    .input {\n        border: 1px solid rgba(0, 0, 0, 0.1);\n        border-radius: 8px;\n        padding: 15px;\n        width: 300px;\n        font-size: 15px;\n        color: #161626;\n\n        &::placeholder {\n            color: rgba(22, 22, 38, 0.3);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AppSearch_container__kiYJV",
	"title": "AppSearch_title__bBHLV",
	"input": "AppSearch_input__KAKTU"
};
export default ___CSS_LOADER_EXPORT___;
