import React, { useState, useEffect } from 'react'
import { CreativeInterface, DraftType } from '../utils/DraftsContext'
import styles from './CreativeCarousel.module.scss'

interface Props {
	creatives: CreativeInterface[]
	activeId: number
	setValue: (name: keyof DraftType, value: number) => void
}

export const CreativeCarousel: React.FC<Props> = ({ creatives, activeId, setValue }) => {
	const creativesWithDefault = [
		...creatives,
		{ id: 0, url: '/images/PDFTemplate/FirstTemplatePage/defaultPhoneScreen.svg' },
	]
	const [active, setActive] = useState(0)
	const prevEnabled = active > 0
	const nextEnabled = active < creativesWithDefault.length - 1

	const handleClickPrev = () => {
		if (prevEnabled) setActive((current) => current - 1)
	}

	const handleClickNext = () => {
		if (nextEnabled) setActive((current) => current + 1)
	}

	useEffect(() => {
		if (activeId !== 0) setActive(creativesWithDefault.map(({ id }) => id).indexOf(activeId))
		else setActive(0)
	}, [creativesWithDefault.length])

	useEffect(() => {
		setValue('creativeId', creativesWithDefault[active].id)
	}, [active])

	return (
		<div className={styles.container}>
			<div className={styles.carousel}>
				{creativesWithDefault.map(({ id, url }, index) => (
					<div
						className={`${styles.creative} ${index === active ? styles.active : ''}`}
						style={{
							backgroundImage: `url('${url}')`,
						}}
						key={url + id}
					/>
				))}
			</div>

			<div className={styles.controlButtons}>
				<div
					className={`${styles.prevbutton} ${!prevEnabled ? styles.disable : ''}`}
					onClick={handleClickPrev}
				/>
				<div
					className={`${styles.nextButton} ${!nextEnabled ? styles.disable : ''}`}
					onClick={handleClickNext}
				/>
			</div>
		</div>
	)
}
