import React from 'react'
import { DraftType } from '../../utils/DraftsContext'
import styles from './Input.module.scss'

interface Props {
    name: keyof DraftType
    title?: string
    placeholder: string
    value: { name: string; file: { name: string; url: string } }
    setValue: (name: keyof DraftType, value: { name: string; file: { name: string; url: string } }) => void
}

export const ManualTextWithFileInput: React.FC<Props> = ({ name, title, placeholder, value, setValue }) => {
    const handleSelectFile = (e: any) => {
        if (e.target.files[0]) {
            const fileName = e.target.files[0].name
            const fileSrc = URL.createObjectURL(e.target.files[0])
            setValue(name, { ...value, file: { name: fileName, url: fileSrc } })
        }
    }

    const handleChangeText = (e: any) => {
        setValue(name, { ...value, name: e.target.value })
    }

    return (
        <div className={styles.container}>
            {title && <span className={styles.title}>{title}</span>}
            <div className={styles.manualTextWithFile}>
                <input
                    className={styles.input}
                    value={value.name}
                    onChange={handleChangeText}
                    type="text"
                    placeholder={placeholder}
                    spellCheck={false}
                />
                <label className={styles.fileLabel}>
                    <input className={styles.fileInput} onChange={handleSelectFile} type="file" accept=".png, .svg" />
                    <span className={value.file.name ? styles.value : styles.placeholder}>
                        {value.file.name ? value.file.name : 'img'}
                    </span>
                </label>
            </div>
        </div>
    )
}
