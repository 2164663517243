import React from 'react'
import { useLocation } from 'react-router-dom'

import { NavBar } from './NavBar'
import { LogoutButton } from './LogoutButton'
import styles from './SideBar.module.scss'

export const SideBar: React.FC = () => {
    const location = useLocation()
    const hideNavBar = /\d+/i.test(location.pathname)

    return (
        <div className={`${styles.container} ${hideNavBar ? styles.hide : ''}`}>
            <div className={styles.header}>
                <img
                    className={styles.logo}
                    src={hideNavBar ? '/images/logoIcon.svg' : '/images/logo.svg'}
                    alt="logo"
                />
            </div>

            <div className={styles.main}>
                <NavBar isIcon={!!hideNavBar} />
            </div>

            <div className={styles.footer}>
                <LogoutButton isIcon={!!hideNavBar} />
            </div>
        </div>
    )
}
