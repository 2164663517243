import React from 'react'
import styles from './BlurBlock.module.scss'

interface Props {
    top?: number
    background: string
    left?: number
    right?: number
    bottom?: number
    transform?: string
    zIndex?: number
    blur?: number
    opacity?: number
}

export const BlurBlock: React.FC<Props> = (props) => {
    const { top, background, left, right, bottom, transform, zIndex, blur, opacity } = props

    return (
        <div
            className={styles.tripleBlur}
            style={{
                top: top && `${top}%`,
                left: left && `${left}%`,
                right: right && `${right}%`,
                bottom: bottom && `${bottom}%`,
                filter: `blur(${blur}px)`,
                opacity,
                background,
                transform,
                zIndex,
            }}
        />
    )
}
