import React, { useContext } from 'react'
import { DraftsContext } from '../utils/DraftsContext'
import { DraftPreview } from './DraftPreview'
import styles from './DraftsPreviewList.module.scss'

interface Props {
    searchName: string
}

export const DraftsPreviewList: React.FC<Props> = ({ searchName }) => {
    const { drafts, createDraft } = useContext(DraftsContext)
    const isDisabledButton = !!searchName

    const filteredDrafts = drafts.filter(({ appTitle }) =>
        (appTitle ?? '').toLowerCase().includes(searchName.toLowerCase())
    )

    return (
        <div className={styles.container}>
            <div className={styles.createButton}>
                <button
                    className={`${styles.plus} ${isDisabledButton ? styles.disabled : ''}`}
                    onClick={isDisabledButton ? () => {} : createDraft}
                />
            </div>

            {filteredDrafts.map((app: any, index: number) => (
                <DraftPreview draft={app} key={app.id} />
            ))}
        </div>
    )
}
