import { DraftType } from '../components/utils/DraftsContext'

export const createApp = async () => {
    const response = await fetch('https://sdr.cdn.persona.ly/api/apps/create', { method: 'POST' })
    const app = await response.json()
    return app.id
}

export const getApp = async (id: number) => {
    const response = await fetch(`https://sdr.cdn.persona.ly/api/apps/${id}`)
    const app = await response.json()
    return app
}

export const getApps = async () => {
    const response = await fetch('https://sdr.cdn.persona.ly/api/apps')
    const apps = await response.json()
    return apps as DraftType[]
}

export const updateApp = async (updatedApp: DraftType) => {
    const response = await fetch(`https://sdr.cdn.persona.ly/api/apps/update?id=${updatedApp.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedApp),
    })
    const app = await response.json()
    return app
}

export const getBundleInfo = (bundleId: string) => {
    return fetch(`https://sdr.cdn.persona.ly/api/apps/find-by-bundle-id/${bundleId}`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((e) => console.log(e))
}

export const removeApp = async (id: number) => {
    return fetch(`https://sdr.cdn.persona.ly/api/apps/remove/${id}`)
        .then((res) => res.json())
        .then((data) => data)
        .catch((e) => console.log(e))
}

export const removeAllApps = async () => {
    return fetch('https://sdr.cdn.persona.ly/api/apps/remove-all')
        .then((res) => res.json())
        .then((data) => console.log(data))
        .catch((e) => console.log(e))
}
