import React, { useContext, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import { updateApp } from '../../api/appsApi'

import { BackButton } from '../../components/BackButton'
import { DraftForm } from '../../components/DraftForm'
import { PDFTemplate } from '../../components/PDFTemplate'
import { DraftsContext, DraftType } from '../../components/utils/DraftsContext'
import { routes } from '../../utils/routes'
import styles from './Draft.module.scss'

export const Draft: React.FC = () => {
	const { deleteDraft, readDraft } = useContext(DraftsContext)
	const pdfRef = useRef<HTMLDivElement>(null)
	const { id } = useParams()
	const navigate = useNavigate()
	const draft = (id && readDraft(+id)) as DraftType

	const [saveLoading, setSaveLoading] = useState(false)
	const [deleteLoading, setDeleteLoading] = useState(false)

	const handleDeleteDraft = () => {
		setDeleteLoading(true)
		deleteDraft(draft.id).then(() => {
			setDeleteLoading(false)
			navigate(routes.drafts, { replace: true })
		})
	}

	if (!draft) {
		return <Navigate to={routes.drafts} replace={true} />
	}

	const handleSaveDraft = (handler: () => void) => {
		setSaveLoading(true)
		updateApp(draft).then(handler)
	}

	return (
		<div className={styles.container}>
			<div className={styles.leftSide}>
				<div className={styles.header}>
					<BackButton onBack={() => handleSaveDraft(() => navigate(-1))} />
					{/* <PDFTemplate currentRef={pdfRef} draft={draft} /> */}
				</div>

				<div className={styles.main}>
					<DraftForm draft={draft} />
				</div>
			</div>

			<div className={styles.rightSide}>
				<div className={styles.preview}>
					<span>Preview</span>
					<PDFTemplate currentRef={pdfRef} draft={draft} />
				</div>
				<div className={styles.controlButtons}>
					<ReactToPrint
						documentTitle={`Persona.ly_${draft.companyName}_Strategic Plan`}
						content={() => pdfRef.current}
						trigger={() => <button className={styles.renderButton}>Render HiRes</button>}
						onAfterPrint={() => handleSaveDraft(() => setSaveLoading(false))}
					/>
					<button className={styles.saveButton} onClick={() => handleSaveDraft(() => setSaveLoading(false))}>
						Save Draft
						{saveLoading && (
							<div className={styles.loader}>
								<i className='fas fa-spinner fa-pulse' />
							</div>
						)}
					</button>
					<button className={styles.deleteButton} onClick={handleDeleteDraft}>
						Delete Draft
						{deleteLoading && (
							<div className={styles.loader}>
								<i className='fas fa-spinner fa-pulse' />
							</div>
						)}
					</button>
				</div>
			</div>
		</div>
	)
}
