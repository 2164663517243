import React from 'react'
import styles from './CreativeFormatsCard.module.scss'

interface Props {
    creative: string
}

export const CreativeFormatsCard: React.FC<Props> = ({ creative }) => {
    return (
        <div className={styles.container}>
            <ul className={styles.formats}>
                <li className={styles.format}>
                    <span className={styles.formatName}>Video:</span>
                    <span className={styles.formatValue}>
                        Full-screen, 1920x1080
                    </span>
                </li>
                <li className={styles.format}>
                    <span className={styles.formatName}>Playable:</span>
                    <span className={styles.formatValue}>
                        Full-screen, HTML-based{' '}
                    </span>
                </li>
                <li className={styles.format}>
                    <span className={styles.formatName}>Dynamic Ads:</span>
                    <span className={styles.formatValue}>
                        Any format and size
                    </span>
                </li>
            </ul>
            <ul className={styles.formats}>
                <li className={styles.format}>
                    <span className={styles.formatName}>Banners:</span>
                    <span className={styles.formatValue}>
                        320x50 and 300x250
                    </span>
                </li>
                <li className={styles.format}>
                    <span className={styles.formatName}>Native Ads:</span>
                    <span className={styles.formatValue}>1200x628</span>
                </li>
            </ul>

            <div className={styles.phoneTemplate}>
                <div
                    className={styles.phone}
                    style={{ backgroundImage: `url('${creative}')` }}
                />
            </div>
        </div>
    )
}
