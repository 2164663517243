import { makeAutoObservable } from 'mobx'

class LoginStore {
    isAuthorized: boolean = false

    constructor() {
        makeAutoObservable(this)
        this.rehydrateInfo()
    }

    login = () => {
        this.isAuthorized = true
        this.storeInfo()
    }

    logout = () => {
        this.isAuthorized = false
        this.storeInfo()
    }

    storeInfo = () => {
        localStorage.setItem('authorized', JSON.stringify(this.isAuthorized))
    }

    rehydrateInfo = () => {
        this.isAuthorized = JSON.parse(localStorage.getItem('authorized') as string)
    }
}

export const loginStore = new LoginStore()
