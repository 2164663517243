import React from 'react'
import { DraftType } from '../../utils/DraftsContext'
import styles from './Input.module.scss'

interface Props {
    name: keyof DraftType
    placeholder: string
    value: string
    setValue: (name: keyof DraftType, value: string) => void
    loading: boolean
    title?: string
}

export const TextInputWithLoader: React.FC<Props> = ({ name, placeholder, value, setValue, loading, title }) => {
    return (
        <div className={styles.container}>
            {title && <span className={styles.title}>{title}</span>}
            <input
                className={styles.input}
                value={value}
                onChange={(e) => setValue(name, e.target.value)}
                type="text"
                placeholder={placeholder}
                spellCheck={false}
            />
            {loading && (
                <div className={styles.loader}>
                    <i className="fas fa-spinner fa-pulse" />
                </div>
            )}
        </div>
    )
}
