import React from 'react'
import { CaseStudyType } from '../../../../utils/FormInfoContext'
import styles from './CaseStudy.module.scss'

interface Props {
    data: CaseStudyType
}

export const CaseStudy: React.FC<Props> = ({ data }) => {
    const { appLogo, deckContent, deckContentImage, resultOne, resultTwo, writerCompanyName } = data

    return (
        <div className={styles.container}>
            <div className={styles.image} style={{ backgroundImage: `url(${deckContentImage})` }} />
            <div className={styles.content}>
                <div className={styles.title}>
                    <div className={styles.icon}>
                        <img src={appLogo} alt="" />
                    </div>
                    <span>{writerCompanyName}</span>
                </div>

                <div className={styles.text}>{deckContent}</div>

                <div className={styles.progress}>
                    {[resultOne, resultTwo].map(
                        (result) =>
                            result.text && (
                                <div className={styles.progressItem} key={result.text}>
                                    <img
                                        src={`/images/PDFTemplate/${
                                            result.arrow === 'up' ? 'growUpBlue' : 'growDownGreen'
                                        }.svg`}
                                        alt=""
                                    />
                                    <div className={styles.progressContent}>
                                        <span className={styles.progressValue}>{result.number}</span>
                                        <span className={styles.progressName}>{result.text}</span>
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
