import React from 'react'
import styles from './AppSearch.module.scss'

interface Props {
    value: string
    setValue: (value: string) => void
}

export const AppSearch: React.FC<Props> = ({ value, setValue }) => {
    return (
        <div className={styles.container}>
            <span className={styles.title}>Search</span>
            <input
                className={styles.input}
                type="text"
                placeholder="Enter Company or Bundle"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    )
}
