import React from 'react'
import styles from './TrafficSourcesCard.module.scss'

export const TrafficSourcesCard: React.FC = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>Bidding Features</h2>
                <p className={styles.text}>We analyze over 60 data points before serving each impression.</p>
            </div>

            <div className={styles.features}>
                <div className={styles.feature}>
                    <div className={styles.featureHeader}>
                        <img src="/images/PDFTemplate/FirstTemplatePage/impressionLevelFeatures.svg" alt="" />
                        <h3>Impression Level Features</h3>
                    </div>

                    <div className={styles.featuresList}>
                        <ul>
                            <li>Placement in App</li>
                            <li>Day & Time</li>
                            <li>Creative Type</li>
                        </ul>
                        <ul>
                            <li>RTB Exchange</li>
                            <li>Historical Placement Performance</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureHeader}>
                        <img src="/images/PDFTemplate/FirstTemplatePage/engagementAnalysis.svg" alt="" />
                        <h3>Engagement Analysis</h3>
                    </div>

                    <div className={styles.featuresList}>
                        <ul>
                            <li>Click Tendency</li>
                            <li>In-ad Behavior</li>
                            <li>Click Depth</li>
                            <li>Viewability</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.feature}>
                    <div className={styles.featureHeader}>
                        <img src="/images/PDFTemplate/FirstTemplatePage/userCentricFeatures.svg" alt="" />
                        <h3>User Centric Features</h3>
                    </div>

                    <div className={styles.featuresList}>
                        <ul>
                            <li>Category Retention</li>
                            <li>Geo Location</li>
                            <li>Installed Apps</li>
                        </ul>
                        <ul>
                            <li>Usage Hours</li>
                            <li>Perceived Persona</li>
                            <li>Session Depth</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
