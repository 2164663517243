import { SegmentType } from '../components/utils/FormInfoContext'

export const rtSegmentsInfo: SegmentType[] = [
    {
        id: 1,
        name: 'D7 Churned (users who stopped being active 7 days ago)',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon1.svg',
    },
    {
        id: 2,
        name: 'Users who made a purchase but stopped using the app 45 days ago',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon2.svg',
    },
    {
        id: 3,
        name: 'Users who made a purchase but stopped using the app',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon2.svg',
    },
    {
        id: 4,
        name: "Users who added an item to a cart but didn't finish the purchase",
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon3.svg',
    },
    {
        id: 5,
        name: 'Users who achieved a certain progress and stopped using the app',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon5.svg',
    },
    {
        id: 6,
        name: 'VIP players aka "whales" that churned',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon6.svg',
    },
    {
        id: 7,
        name: 'Users who installed the app but never signed up',
        url: '/images/PDFTemplate/SecondTemplatePage/testSegmentIcon7.svg',
    },
]
