import React, { useContext, useEffect, useState } from 'react'

import { TextInput } from './Inputs/TextInput'
import { DraftsContext, DraftType } from '../utils/DraftsContext'
import styles from './DraftForm.module.scss'
import { MultiDropDownInput } from './Inputs/MultiDropDownInput'
import { formatCountryOption } from '../../utils/format'
import { SingleDropDownInput } from './Inputs/SingleDropDownInput'
import { CaseStudyType, CountryType, FormInfoContext, GenderType } from '../utils/FormInfoContext'
import { useDebounce } from '../../hooks/useDebounce'
import { FileInput } from './Inputs/FileInput'
import { getBundleInfo } from '../../api/appsApi'
import { CreativeCarousel } from '../CreativeCarousel'
import { SimilarApps } from '../SimilarApps'
import { ManualTextWithFileInput } from './Inputs/ManualTextWithFileInput'
import { TextInputWithLoader } from './Inputs/TextInputWithLoader'

interface Props {
	draft: DraftType
}

export const DraftForm: React.FC<Props> = ({ draft }) => {
	const { updateDraft } = useContext(DraftsContext)
	const { uaCases, rtCases, rtSegments, ages, countries, genders, appVerticals } = useContext(FormInfoContext)
	const bundleId = draft['bundleId']

	const uaCasesOptions = uaCases.map((item: CaseStudyType) => ({
		value: item._id,
		label: item.writerCompanyName,
	}))
	const uaFirstCasesValue = uaCases
		.filter(({ _id }) => draft['firstCaseStudyId'] === _id)
		.map(({ _id, writerName }) => ({
			value: `${_id}`,
			label: writerName,
		}))[0]

	const uaSecondCasesValue = uaCases
		.filter(({ _id }) => draft['secondCaseStudyId'] === _id)
		.map(({ _id, writerName }) => ({
			value: `${_id}`,
			label: writerName,
		}))[0]

	const rtCasesOptions = rtCases.map((item: CaseStudyType) => ({
		value: item._id,
		label: item.writerCompanyName,
	}))
	const rtCasesValue = rtCases
		.filter(({ _id }) => draft['rtCaseStudyId'] === _id)
		.map(({ _id, writerName }) => ({
			value: `${_id}`,
			label: writerName,
		}))[0]

	const rtSegmentsOptions = rtSegments.map(({ id, name }) => ({
		value: id,
		label: name,
	}))
	const rtFirstSegmentsValue = rtSegments
		.filter(({ id }) => draft['firstSegmentId'] === id)
		.map(({ id, name }) => ({ value: `${id}`, label: name }))[0]

	const rtSecondSegmentsValue = rtSegments
		.filter(({ id }) => draft['firstSegmentId'] === id)
		.map(({ id, name }) => ({ value: `${id}`, label: name }))[0]

	const rtThirdSegmentsValue = rtSegments
		.filter(({ id }) => draft['secondSegmentId'] === id)
		.map(({ id, name }) => ({ value: `${id}`, label: name }))[0]

	const agesOptions = ages.map(({ id, name }) => ({ value: id, label: name }))
	const agesValue = ages
		.filter(({ id }) => (draft['ageIds'] ?? []).includes(id))
		.map(({ id, name }) => ({ value: id, label: name }))

	const countriesOptions = countries.map((item: CountryType) => ({
		value: item.code,
		label: item.name,
	}))
	const countriesValue = countries
		.filter(({ code }) => (draft['country'] ?? []).includes(code))
		.map(({ code, name }) => ({ value: code, label: name }))

	const gendersOptions = genders.map((item: GenderType) => ({
		value: item.id,
		label: item.name,
	}))
	const gendersValue = genders
		.filter(({ id }) => +draft['genderId'] === id)
		.map(({ id, name }) => ({ value: id, label: name }))[0]

	const appVerticalsOptions = appVerticals.map((item: GenderType) => ({
		value: item.id,
		label: item.name,
	}))
	const appVerticalsValues = appVerticals
		.filter(({ id }) => draft['verticalId'] === id)
		.map(({ id, name }) => ({ value: id, label: name }))[0]

	const [isAppSearching, setIsAppSearching] = useState(false)

	const handleChange = (name: keyof DraftType, value: DraftType[keyof DraftType] | null) => {
		updateDraft({ ...draft, [name]: value })
	}

	let debouncedSearchQuery = useDebounce(draft['bundleId'], 500)
	const search = async () => {
		setIsAppSearching(true)
		const bundleInfo = await getBundleInfo(bundleId)
		if (bundleInfo && bundleInfo.app_name) {
			updateDraft({
				...draft,
				appIcon: bundleInfo.app_icon ?? '',
				appTitle: bundleInfo.app_name ?? '',
				manualSimilarAppsBundleId: '',
				manualSimilarAppBundleId: '',
				creativesInfo: bundleInfo.creatives ?? [],
				similarAppsInfo: bundleInfo.similar_apps ?? [],
				creativeId: 0,
			})
		} else {
			updateDraft({
				...draft,
				appIcon: '',
				appTitle: '',
				manualSimilarAppsBundleId: '',
				manualSimilarAppBundleId: '',
				creativesInfo: [],
				similarAppsInfo: [],
				creativeId: 0,
			})
		}

		setIsAppSearching(false)
	}

	useEffect(() => {
		debouncedSearchQuery = ''
	}, [])

	useEffect(() => {
		if (debouncedSearchQuery) search()
	}, [debouncedSearchQuery])

	return (
		<div className={styles.container}>
			<div className={styles.leftSide}>
				<div className={styles.appIcon}>
					<span className={styles.title}>App Icon</span>
					<div className={styles.iamge}>{draft['appIcon'] && <img src={draft['appIcon']} alt="" />}</div>
				</div>

				<div className={styles.creative}>
					<span className={styles.title}>Creative</span>
					<div className={styles.iamge}>
						<CreativeCarousel
							creatives={draft['creativesInfo']}
							activeId={draft['creativeId']}
							setValue={handleChange}
						/>
					</div>
				</div>
			</div>

			<div className={styles.rightSide}>
				<div className={styles.row}>
					<TextInputWithLoader
						name="bundleId"
						title="Bundle ID"
						placeholder="Insert Bundle ID"
						value={draft['bundleId']}
						setValue={handleChange}
						loading={isAppSearching}
					/>

					<TextInput
						name="appTitle"
						title="App Title"
						placeholder="Insert App Title"
						value={draft['appTitle']}
						setValue={handleChange}
					/>
				</div>

				<div className={styles.row}>
					<TextInput
						name="companyName"
						title="Company"
						placeholder="Insert Company name"
						value={draft['companyName']}
						setValue={handleChange}
					/>

					<FileInput
						name="companyLogo"
						title="Company Logo"
						placeholder="Insert Company Logo"
						value={draft['companyLogo']}
						setValue={handleChange}
					/>
				</div>

				<div className={styles.row}>
					<SingleDropDownInput
						name="genderId"
						title="Targeting Gender"
						placeholder="Select Gender"
						options={gendersOptions}
						value={gendersValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>

					<MultiDropDownInput
						name="ageIds"
						title="Targeting Age"
						placeholder="Select Age Group(s)"
						options={agesOptions}
						value={agesValue}
						setValue={handleChange}
					/>

					<MultiDropDownInput
						name="country"
						title="Countries"
						placeholder="Select up to 3 options"
						options={countriesOptions}
						value={countriesValue}
						setValue={handleChange}
						formatOption={formatCountryOption}
						limit={3}
					/>
				</div>
				<div className={styles.row}>
					<SingleDropDownInput
						name="firstCaseStudyId"
						title="Case Studies"
						placeholder="Select CS 1"
						options={uaCasesOptions}
						value={uaFirstCasesValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>

					<SingleDropDownInput
						name="secondCaseStudyId"
						placeholder="Select CS 2"
						options={uaCasesOptions}
						value={uaSecondCasesValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>
				</div>

				<div className={styles.row}>
					<SingleDropDownInput
						name="firstSegmentId"
						title="RT Segments"
						placeholder="Segment 1"
						options={rtSegmentsOptions}
						value={rtFirstSegmentsValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>

					<SingleDropDownInput
						name="secondSegmentId"
						placeholder="Segment 2"
						options={rtSegmentsOptions}
						value={rtSecondSegmentsValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>

					<SingleDropDownInput
						name="thirdSegmentId"
						placeholder="Segment 3"
						options={rtSegmentsOptions}
						value={rtThirdSegmentsValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>
				</div>

				<div className={styles.row}>
					<ManualTextWithFileInput
						name="manualFirstSegment"
						title="RT Segments - Manual"
						placeholder="Manual Segment 1"
						value={
							draft['manualFirstSegment'] ?? {
								name: '',
								file: { name: '', url: '' },
							}
						}
						setValue={handleChange}
					/>

					<ManualTextWithFileInput
						name="manualSecondSegment"
						placeholder="Manual Segment 2"
						value={
							draft['manualSecondSegment'] ?? {
								name: '',
								file: { name: '', url: '' },
							}
						}
						setValue={handleChange}
					/>

					<ManualTextWithFileInput
						name="manualThirdSegment"
						placeholder="Manual Segment 3"
						value={
							draft['manualThirdSegment'] ?? {
								name: '',
								file: { name: '', url: '' },
							}
						}
						setValue={handleChange}
					/>
				</div>

				<div className={styles.row}>
					<SingleDropDownInput
						name="rtCaseStudyId"
						title="RT Case Study"
						placeholder="Select CS"
						options={rtCasesOptions}
						value={rtCasesValue ?? { value: '', label: '' }}
						setValue={handleChange}
					/>

					<TextInput
						name="craftedBy"
						title="Crafted By"
						placeholder="Enter username"
						value={draft['craftedBy']}
						setValue={handleChange}
					/>

					<SingleDropDownInput
						name="verticalId"
						title="App Vertical"
						placeholder="Select vertical"
						options={appVerticalsOptions}
						value={appVerticalsValues ?? { value: '', label: '' }}
						setValue={handleChange}
						manualFieldName="manualVertical"
						manualValue={draft['manualVertical']}
					/>
				</div>

				<div className={styles.row}>
					<SimilarApps
						similarApps={draft['similarAppsInfo']}
						value={draft['similarApps']}
						setValue={handleChange}
						manualAppsBundleId={draft['manualSimilarAppsBundleId']}
						manualAppBundleId={draft['manualSimilarAppBundleId']}
					/>
				</div>
			</div>
		</div>
	)
}
