// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavBar_link__a9yT1 {\n  position: relative;\n  font-size: 15px;\n  width: 215px;\n  padding: 15px;\n  color: #161626;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n}\n.NavBar_link__a9yT1 .NavBar_linkIcon__DGaSU {\n  margin-right: 10px;\n  width: 25px;\n  height: 25px;\n}\n.NavBar_link__a9yT1.NavBar_active__oqx9m {\n  font-weight: 500;\n  color: #ffffff;\n  background: #222242;\n}\n.NavBar_link__a9yT1.NavBar_icon__UVVxK {\n  width: inherit;\n}\n.NavBar_link__a9yT1.NavBar_icon__UVVxK .NavBar_linkIcon__DGaSU {\n  margin-right: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/SideBar/NavBar/NavBar.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AACR;AAEI;EACI,gBAAA;EACA,cAAA;EACA,mBAAA;AAAR;AAGI;EACI,cAAA;AADR;AAGQ;EACI,eAAA;AADZ","sourcesContent":[".link {\n    position: relative;\n    font-size: 15px;\n    width: 215px;\n    padding: 15px;\n    color: #161626;\n    border-radius: 8px;\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n\n    .linkIcon {\n        margin-right: 10px;\n        width: 25px;\n        height: 25px;\n    }\n\n    &.active {\n        font-weight: 500;\n        color: #ffffff;\n        background: #222242;\n    }\n\n    &.icon {\n        width: inherit;\n\n        .linkIcon {\n            margin-right: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "NavBar_link__a9yT1",
	"linkIcon": "NavBar_linkIcon__DGaSU",
	"active": "NavBar_active__oqx9m",
	"icon": "NavBar_icon__UVVxK"
};
export default ___CSS_LOADER_EXPORT___;
