import React from 'react'
import { CaseStudyType } from '../../../utils/FormInfoContext'
import styles from './CaseStudiesCard.module.scss'
import { CaseStudy } from './CaseStudy'

interface Props {
    data: {
        case1: CaseStudyType
        case2: CaseStudyType
    }
}

export const CaseStudiesCard: React.FC<Props> = ({ data }) => {
    const { case1, case2 } = data

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>Our Case Studies</h2>
                <p className={styles.text}>See how our clients maximize their revenue with Persona.ly</p>
            </div>
            <div className={styles.cases}>
                <CaseStudy data={case1} />
                <CaseStudy data={case2} />
            </div>
        </div>
    )
}
