// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextareaWithAutosize_textarea__dbwq9 {\n  resize: none;\n  width: 100%;\n  font-weight: 500;\n  font-size: 16px;\n  color: #000000;\n}\n.TextareaWithAutosize_textarea__dbwq9:focus {\n  outline: 1px solid #d6d5d5;\n  border-radius: 2px;\n}\n.TextareaWithAutosize_textarea__dbwq9::-webkit-scrollbar {\n  width: 3px;\n  background-color: #f4f4f4;\n}\n.TextareaWithAutosize_textarea__dbwq9::-webkit-scrollbar-thumb {\n  background-color: #dadada;\n}", "",{"version":3,"sources":["webpack://./src/components/TextareaWithAutosize/TextareaWithAutosize.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACJ;AACI;EACI,0BAAA;EACA,kBAAA;AACR;AAEI;EACI,UAAA;EACA,yBAAA;AAAR;AAGI;EACI,yBAAA;AADR","sourcesContent":[".textarea {\n    resize: none;\n    width: 100%;\n    font-weight: 500;\n    font-size: 16px;\n    color: #000000;\n\n    &:focus {\n        outline: 1px solid #d6d5d5;\n        border-radius: 2px;\n    }\n\n    &::-webkit-scrollbar {\n        width: 3px;\n        background-color: #f4f4f4;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background-color: #dadada;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "TextareaWithAutosize_textarea__dbwq9"
};
export default ___CSS_LOADER_EXPORT___;
