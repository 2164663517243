// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Drafts_container__jWkdm {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-left: 60px;\n  padding-right: 40px;\n}\n.Drafts_container__jWkdm .Drafts_header__iUNbm {\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: calc(15% - 40px);\n  margin: 20px 0;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n.Drafts_container__jWkdm .Drafts_main__l1QQ\\+ {\n  flex: 0 0 82%;\n  overflow: auto;\n  position: relative;\n}\n.Drafts_container__jWkdm .Drafts_main__l1QQ\\+::-webkit-scrollbar {\n  width: 10px;\n  background-color: #ececec;\n  border-radius: 10px;\n}\n.Drafts_container__jWkdm .Drafts_main__l1QQ\\+::-webkit-scrollbar-thumb {\n  background-color: #b8b5b5;\n  border-radius: 10px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Drafts/Drafts.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EAAA,cAAA;EAAA,4BAAA;EACA,cAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;AACR;AAEI;EACI,aAAA;EACA,cAAA;EACA,kBAAA;AAAR;AAEQ;EACI,WAAA;EACA,yBAAA;EACA,mBAAA;AAAZ;AAGQ;EACI,yBAAA;EACA,mBAAA;AADZ","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    padding-left: 60px;\n    padding-right: 40px;\n\n    .header {\n        flex: 0 0 calc(15% - 40px);\n        margin: 20px 0;\n        display: flex;\n        justify-content: flex-end;\n        align-items: center;\n    }\n\n    .main {\n        flex: 0 0 82%;\n        overflow: auto;\n        position: relative;\n\n        &::-webkit-scrollbar {\n            width: 10px;\n            background-color: #ececec;\n            border-radius: 10px;\n        }\n\n        &::-webkit-scrollbar-thumb {\n            background-color: #b8b5b5;\n            border-radius: 10px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Drafts_container__jWkdm",
	"header": "Drafts_header__iUNbm",
	"main": "Drafts_main__l1QQ+"
};
export default ___CSS_LOADER_EXPORT___;
