import React from 'react'
import { getCountryFlag } from '../../../../utils/format'
import { CountryInterface, SimilarAppInterface } from '../../../utils/DraftsContext'
import styles from './DspCard.module.scss'

interface Props {
	appTitle: string
	appIcon: string
	gender: string
	age: string
	countries: CountryInterface[]
	company: string
	similarApps: SimilarAppInterface[]
}

export const DspCard: React.FC<Props> = ({ appTitle, appIcon, gender, age, countries, company, similarApps }) => {
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.app}>
					<div className={styles.appIcon}>{appIcon && <img src={appIcon} alt='' />}</div>
					<div className={styles.appContent}>
						<span>User Acquisition Strategy for</span>
						<span className={styles.appBundle}>{appTitle ? appTitle : '{A Bundle title}'}</span>
					</div>
				</div>
			</div>

			<div className={styles.main}>
				<img
					className={styles.processArrows}
					src='/images/PDFTemplate/FirstTemplatePage/processArrows.svg'
					alt=''
				/>

				<div className={styles.explorationStrategy}>
					<div className={styles.exploration}>
						<img src='/images/PDFTemplate/FirstTemplatePage/campaignExploration.svg' alt='' />
						<div className={styles.explorationContent}>
							<h3 className={styles.textTitle}>Campaign Exploration</h3>
							<span className={styles.text}>Targeting users of similar apps</span>
							<div className={styles.similarApps}>
								{similarApps.length ? (
									similarApps.map(({ appName, appIcon }) => (
										<div className={styles.similarApp} key={appName + appIcon}>
											<img className={styles.similarAppIcon} src={appIcon} alt='' />
											<span className={styles.similarAppName}>{appName}</span>
										</div>
									))
								) : (
									<div className={styles.similarApp}>
										<img
											className={styles.similarAppIcon}
											src='/images/PDFTemplate/FirstTemplatePage/testSiimilarApp.svg'
											alt=''
										/>
										<span className={styles.similarAppName}>
											App <br /> Name
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className={styles.targetSegment}>
						<span className={styles.text}>
							Bidding on lookalike audience based on unattributed data provided by{' '}
							{company ? company : '{company name}'}.
						</span>
						<div className={styles.target}>
							<div className={styles.targetUser}>
								<img src='/images/PDFTemplate/FirstTemplatePage/userIcon.svg' alt='' />
								<div className={styles.targetUserContent}>
									<span>Age</span>
									<span>
										{age ? age : '{age}'}, {gender ? gender.toLowerCase() : '{gender}'}
									</span>
								</div>
							</div>
							<div className={styles.targetCountries}>
								<img src='/images/PDFTemplate/FirstTemplatePage/locationIcon.svg' alt='' />
								{countries.map(({ code }) => getCountryFlag(code, 36))}
							</div>
						</div>
					</div>
				</div>
				<div className={styles.strategyItem}>
					<img src='/images/PDFTemplate/FirstTemplatePage/basicLogicLevel.svg' alt='' />
					<div className={styles.strategyItemContent}>
						<h3 className={styles.textTitle}>Basic Logic Level</h3>
						<span className={styles.text}>
							A basic targeting algorithm based on the initially gathered data and proven strategies.
						</span>
					</div>
				</div>
				<div className={styles.strategyItem}>
					<img src='/images/PDFTemplate/FirstTemplatePage/uniqueClassificationModel.svg' alt='' />
					<div className={styles.strategyItemContent}>
						<h3 className={styles.textTitle}>Unique classification model</h3>
						<span className={styles.text}>
							Once we reach 100 installs mark, our machine learning algorithm will create a unique
							classification model based on the users who have converted.
						</span>
					</div>
				</div>
				<div className={styles.strategyItem}>
					<img src='/images/PDFTemplate/FirstTemplatePage/engagementOptimization.svg' alt='' />
					<div className={styles.strategyItemContent}>
						<h3 className={styles.textTitle}>Engagement optimization</h3>
						<span className={styles.text}>
							Full-funnel data (from ad click to in-app purchase) allows us to modify the algorithm to
							optimize towards improved retention and in-app purchases.
						</span>
					</div>
				</div>
				<div className={styles.strategyItem}>
					<img src='/images/PDFTemplate/FirstTemplatePage/campaignScaling.svg' alt='' />
					<div className={styles.strategyItemContent}>
						<h3 className={styles.textTitle}>Campaign Scaling</h3>
						<span className={styles.text}>
							Further campaign scaling to expedite the campaign and acquire more targeted users.
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
