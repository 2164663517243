import React from 'react'
import { ImageInterface, DraftType } from '../../utils/DraftsContext'
import styles from './Input.module.scss'

interface Props {
    name: keyof DraftType
    title?: string
    placeholder: string
    value: ImageInterface
    setValue: (name: keyof DraftType, value: ImageInterface) => void
}

export const FileInput: React.FC<Props> = ({ name, title, placeholder, value, setValue }) => {
    const handleSelectFile = (e: any) => {
        if (e.target.files[0]) {
            const fileName = e.target.files[0].name
            const fileSrc = URL.createObjectURL(e.target.files[0])
            setValue(name, { name: fileName, url: fileSrc })
        }
    }

    return (
        <div className={styles.container}>
            {title && <span className={styles.title}>{title}</span>}
            <label className={styles.fileLabel}>
                <input
                    className={styles.fileInput}
                    onChange={handleSelectFile}
                    type="file"
                    accept=".png, .svg, .jpg, .jpeg, .webp"
                />
                <span className={value.name ? styles.value : styles.placeholder}>
                    {value.name ? value.name : placeholder}
                </span>
            </label>
        </div>
    )
}
