import React, { useEffect, useState } from 'react'
import { TextareaWithAutosize } from '../TextareaWithAutosize'
import { DraftType, SimilarAppInterface } from '../utils/DraftsContext'
import { CheckboxInput } from '../DraftForm/Inputs/CheckboxInput'
import { TextInputWithLoader } from '../DraftForm/Inputs/TextInputWithLoader'
import { useDebounce } from '../../hooks/useDebounce'
import { getBundleInfo } from '../../api/appsApi'
import styles from './SimilarApps.module.scss'

interface Props {
    similarApps: SimilarAppInterface[]
    value: SimilarAppInterface[]
    setValue: (name: keyof DraftType, value: SimilarAppInterface[] | string) => void
    manualAppsBundleId: string
    manualAppBundleId: string
}

export const SimilarApps: React.FC<Props> = ({
    similarApps,
    value,
    setValue,
    manualAppsBundleId,
    manualAppBundleId,
}) => {
    const needAppDisable = value.length >= 5
    const checkIsAppSelected = (appIcon: string) => !!value.find((app) => app.appIcon === appIcon)
    const [isAppsSearching, setIsAppsSearching] = useState(false)
    const [isAppSearching, setIsAppSearching] = useState(false)

    let debouncedAppsSearchQuery = useDebounce(manualAppsBundleId, 500)
    let debouncedAppSearchQuery = useDebounce(manualAppBundleId, 500)

    const handleSelectApp = (app: SimilarAppInterface) => {
        if (checkIsAppSelected(app.appIcon)) {
            setValue(
                'similarApps',
                value.filter((item) => item.appIcon !== app.appIcon)
            )
        } else {
            setValue('similarApps', [...value, app])
        }
    }

    const handleChangeAppName = (appName: string, appIcon: string) => {
        setValue(
            'similarAppsInfo',
            similarApps.map((app) => (app.appIcon === appIcon ? { appName, appIcon } : app))
        )
    }

    const searchApps = async () => {
        setIsAppsSearching(true)
        const bundleInfo = await getBundleInfo(manualAppsBundleId)

        if (bundleInfo && bundleInfo.app_name) {
            const newSimilarApps = (bundleInfo.similar_apps as SimilarAppInterface[]).filter(
                ({ appIcon }) => !similarApps.find((item) => item.appIcon === appIcon)
            )
            setValue('similarAppsInfo', [...similarApps, ...newSimilarApps])
        }
        setIsAppsSearching(false)
    }

    const searchApp = async () => {
        setIsAppSearching(true)
        const bundleInfo = await getBundleInfo(manualAppBundleId)

        if (bundleInfo && bundleInfo.app_name) {
            const newSimilarApp = { appName: bundleInfo.app_name, appIcon: bundleInfo.app_icon }
            const newSimilarApps = [newSimilarApp].filter(
                ({ appIcon }) => !similarApps.find((item) => item.appIcon === appIcon)
            )
            setValue('similarAppsInfo', [...similarApps, ...newSimilarApps])
        }

        setIsAppSearching(false)
    }

    useEffect(() => {
        debouncedAppsSearchQuery = ''
        debouncedAppSearchQuery = ''
    }, [])

    useEffect(() => {
        if (debouncedAppsSearchQuery) searchApps()
    }, [debouncedAppsSearchQuery])

    useEffect(() => {
        if (debouncedAppSearchQuery) searchApp()
    }, [debouncedAppSearchQuery])

    return (
        <div className={styles.container}>
            <span className={styles.title}>Similar Apps</span>
            <div className={styles.content}>
                <div className={styles.apps}>
                    {similarApps.length ? (
                        similarApps.map(({ appName, appIcon }) => (
                            <div
                                className={`${styles.app} ${
                                    needAppDisable && !checkIsAppSelected(appIcon) ? styles.disable : ''
                                }`}
                                key={appName + appIcon}
                            >
                                <img className={styles.appIcon} src={appIcon} alt="" />
                                <div className={styles.appName}>
                                    <TextareaWithAutosize
                                        initialValue={appName}
                                        setInitialValue={(appName) => handleChangeAppName(appName, appIcon)}
                                        placeholder="App Title"
                                    />
                                </div>
                                <div className={styles.appCheckbox}>
                                    <CheckboxInput
                                        checked={checkIsAppSelected(appIcon)}
                                        onChange={() => handleSelectApp({ appName, appIcon })}
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>-</div>
                    )}
                </div>

                <div className={styles.inputs}>
                    <TextInputWithLoader
                        name="manualSimilarAppsBundleId"
                        placeholder="Insert Bundle ID (Apps)"
                        value={manualAppsBundleId}
                        setValue={setValue}
                        loading={isAppsSearching}
                    />

                    <TextInputWithLoader
                        name="manualSimilarAppBundleId"
                        placeholder="Insert Bundle ID (App)"
                        value={manualAppBundleId}
                        setValue={setValue}
                        loading={isAppSearching}
                    />
                </div>
            </div>
        </div>
    )
}
