import { AgeType, AppVerticalType, CaseStudyType, CountryType, GenderType } from '../components/utils/FormInfoContext'

export const getAges = async () => {
    const response = await fetch('https://sdr.cdn.persona.ly/api/ages')
    const ages = await response.json()
    return ages as AgeType[]
}

export const getCaseStudies = async () => {
    const result = await fetch(`https://sdk-staging.cdn.persona.ly/cms/api/case-study`)
    const data = await result.json()
    const cases = data.results
    return cases as CaseStudyType[]
}

export const getCountries = async () => {
    const result = await fetch(`https://sdr.cdn.persona.ly/api/countries`)
    const countries = await result.json()
    return countries as CountryType[]
}

export const getGenders = async () => {
    const result = await fetch(`https://sdr.cdn.persona.ly/api/genders`)
    const genders = await result.json()
    return genders as GenderType[]
}

export const getAppVerticals = async () => {
    const result = await fetch(`https://sdr.cdn.persona.ly/api/verticals`)
    const verticals = await result.json()
    return verticals as AppVerticalType[]
}
