import React from 'react'
import { Link } from 'react-router-dom'

import { loginStore } from '../../../store/LoginStore'
import { routes } from '../../../utils/routes'
import styles from './LogoutButton.module.scss'

interface Props {
    isIcon?: boolean
}

export const LogoutButton: React.FC<Props> = ({ isIcon }) => (
    <Link
        className={`${styles.link} ${isIcon ? styles.icon : ''}`}
        to={routes.login}
        onClick={() => loginStore.logout()}
    >
        <img className={styles.linkIcon} src={'/images/logoutNavIcon.png'} alt="logout" />
        {isIcon ? '' : 'logout'}
    </Link>
)
