import { StylesConfig } from 'react-select'
import { OptionType } from './inputTypes'

export const dropDownStyles: StylesConfig<OptionType> = {
    placeholder: (styles) => ({
        ...styles,
        color: 'rgba(22, 22, 38, 0.3)',
    }),
    control: (styles) => ({
        ...styles,
        'fontSize': '15px',
        'color': '#161626',
        'border': '1px solid rgba(0, 0, 0, 0.1)',
        'borderRadius': '8px',
        'padding': '9px 5px',
        'boxShadow': 'none',
        'minHeight': '57px',
        ':hover': {
            ...styles[':hover'],
            border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        ':focus': {
            ...styles[':focus'],
        },
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: '#3adfd71a',
        }
    },
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            backgroundColor: '#f9001847',
        },
    }),
    menu: (styles) => ({
        ...styles,
        borderRadius: '8px',
        boxShadow: '0px 12px 25px rgba(24, 32, 46, 0.08)',
    }),
    menuList: (styles) => ({
        ...styles,
        'padding': '0 5px',
        '::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#ececec',
            borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#b8b5b5',
            borderRadius: '10px',
        },
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
    }),
    option: (styles, { isSelected, isDisabled }) => ({
        ...styles,
        'fontSize': '15px',
        'fontWeight': '400',
        'opacity': isDisabled ? '0.7' : '1',
        'color': isDisabled ? '#afadadbd' : isSelected ? '#22CCC6' : '#161626',
        'backgroundColor': isDisabled ? '#dddddd57' : isSelected ? '#3adfd71a' : '#ffffff',
        'borderRadius': '4px',
        'margin': '5px 0',
        ':hover': {
            ...styles[':hover'],
            backgroundColor: isDisabled ? '#dddddd57' : isSelected ? '#3adfd71a' : '#a2d5d226',
        },
    }),
}
