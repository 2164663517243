import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BlurBlock } from '../../components/BlurBlock'
import { loginStore } from '../../store/LoginStore'
import { routes } from '../../utils/routes'
import styles from './Login.module.scss'

export const Login: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const handleSubmit = () => {
        if (email === 'test@gmail.com' && password === '123') {
            loginStore.login()
            navigate(routes.drafts, { replace: true })
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                <img className={styles.logo} src="images/logo.svg" alt="logo" />
                <span className={styles.title}>Deck Gen</span>

                <input
                    className={styles.formInput}
                    type="email"
                    placeholder="Login"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
                <input
                    className={styles.formInput}
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                />

                <button className={styles.formSubmit} onClick={handleSubmit}>
                    LOG IN
                </button>
            </div>

            <BlurBlock top={15} left={15} background="linear-gradient(180deg, #FF6262 0%, #FBB251 100%)" />
            <BlurBlock top={95} right={10} background="linear-gradient(180deg, #00D4F9 0%, #1FEAAA 100%)" />
        </div>
    )
}
