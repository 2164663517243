// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PDFTemplate_container__YnKAM {\n  max-height: 3840px;\n  zoom: 25%;\n  display: block;\n  font-family: \"Poppins\", sans-serif;\n}\n\n@media print {\n  .PDFTemplate_container__YnKAM {\n    display: block;\n    zoom: 0.6;\n  }\n  @page {\n    size: 1152px 648px;\n    font-family: \"Poppins\", sans-serif;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/PDFTemplate/PDFTemplate.module.scss"],"names":[],"mappings":"AAIA;EACI,kBAAA;EACA,SAAA;EACA,cAAA;EACA,kCAAA;AAHJ;;AAMA;EACI;IACI,cAAA;IACA,SAdD;EAWL;EAME;IACI,kBAAA;IACA,kCAAA;EAJN;AACF","sourcesContent":["$zoom: 0.6;\n$pageWidth: $zoom * 1920px;\n$pageHeight: $zoom * 1080px;\n\n.container {\n    max-height: 3840px;\n    zoom: 25%;\n    display: block;\n    font-family: 'Poppins', sans-serif;\n}\n\n@media print {\n    .container {\n        display: block;\n        zoom: $zoom;\n    }\n\n    @page {\n        size: $pageWidth $pageHeight;\n        font-family: 'Poppins', sans-serif;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PDFTemplate_container__YnKAM"
};
export default ___CSS_LOADER_EXPORT___;
