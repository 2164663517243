import { routes } from '../../../utils/routes'

interface NavBarItem {
    image: string
    name: string
    url: string
}

export const navBarConfiguration: NavBarItem[] = [
    {
        name: 'My Drafts',
        url: routes.drafts,
        image: '/images/draftsNavIcon.png',
    },
]
