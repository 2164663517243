import React, { ReactNode, createContext, useState, useEffect } from 'react'
import { getAges, getAppVerticals, getCaseStudies, getCountries, getGenders } from '../../api/formInfoApi'
import { rtSegmentsInfo } from '../../utils/rtSegmentsInfo'

export const defaultCaseStudy = {
	_id: '',
	appLogo: '',
	caseStudyType: '',
	deckContent: '',
	deckContentImage: '',
	resultOne: { arrow: '', number: '', text: '' },
	resultTwo: { arrow: '', number: '', text: '' },
	title: '',
	writerCompanyName: '',
	writerImage: '',
	writerName: '',
	testimonial: '',
}

export const defaultRtSegment = {
	id: 0,
	name: '',
	url: '',
}

export type CaseStudyType = {
	_id: string
	appLogo: string
	caseStudyType: string
	deckContent: string
	deckContentImage: string
	resultOne: { arrow: string; number: string; text: string }
	resultTwo: { arrow: string; number: string; text: string }
	title: string
	writerCompanyName: string
	writerImage: string
	writerName: string
	testimonial: string
}

export type SegmentType = {
	id: number
	name: string
	url: string
}

export type AgeType = {
	id: number
	name: string
}

export type CountryType = {
	code: string
	name: string
}

export type SimilarAppType = {
	appName: string
	appIcon: string
}

export type GenderType = {
	id: number
	name: string
}

export type AppVerticalType = {
	id: number
	name: string
}

interface FormInfoContextInterface {
	uaCases: CaseStudyType[]
	rtCases: CaseStudyType[]
	rtSegments: SegmentType[]
	ages: AgeType[]
	countries: CountryType[]
	genders: GenderType[]
	appVerticals: AppVerticalType[]
}

export const FormInfoContext = createContext<FormInfoContextInterface>({
	uaCases: [],
	rtCases: [],
	rtSegments: [],
	ages: [],
	countries: [],
	genders: [],
	appVerticals: [],
})

interface Props {
	children: ReactNode
}

export const FormInfoContextProvider: React.FC<Props> = ({ children }) => {
	const [uaCases, setUaCases] = useState<CaseStudyType[]>([])
	const [rtCases, setRtCases] = useState<CaseStudyType[]>([])
	const [rtSegments, setRtSegments] = useState<SegmentType[]>([])
	const [ages, setAges] = useState<AgeType[]>([])
	const [countries, setCountries] = useState<CountryType[]>([])
	const [genders, setGenders] = useState<GenderType[]>([])
	const [appVerticals, setAppVerticals] = useState<AppVerticalType[]>([])

	useEffect(() => {
		getCaseStudies().then((cases) => {
			setRtCases(cases.filter(({ caseStudyType }) => caseStudyType === 'RT'))
			setUaCases(cases.filter(({ caseStudyType }) => caseStudyType === 'UA'))
		})

		getAges().then((ages) => {
			setAges(ages)
		})

		getCountries().then((countries) => {
			setCountries(countries)
		})

		getGenders().then((genders) => {
			setGenders(genders)
		})

		getAppVerticals().then((verticals) => {
			setAppVerticals(verticals)
		})

		setRtSegments(rtSegmentsInfo)
	}, [])

	return (
		<FormInfoContext.Provider
			value={{
				uaCases,
				rtCases,
				rtSegments,
				ages,
				countries,
				genders,
				appVerticals,
			}}
		>
			{children}
		</FormInfoContext.Provider>
	)
}
