import React, { ReactNode } from 'react'
import { SideBar } from '../SideBar'
import styles from './Layout.module.scss'

interface Props {
    children: ReactNode
}

export const Layout: React.FC<Props> = ({ children }) => {
    return (
        <div className={styles.layout}>
            <SideBar />
            {children}
        </div>
    )
}
