// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoutButton_link__49om3 {\n  font-weight: 500;\n  font-size: 14px;\n  color: #161626;\n  width: 215px;\n  padding: 15px;\n  display: flex;\n  align-items: center;\n}\n.LogoutButton_link__49om3 .LogoutButton_linkIcon__1sgfe {\n  margin-right: 10px;\n}\n.LogoutButton_link__49om3.LogoutButton_icon__jxtWu {\n  width: inherit;\n}\n.LogoutButton_link__49om3.LogoutButton_icon__jxtWu .LogoutButton_linkIcon__1sgfe {\n  margin-right: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/SideBar/LogoutButton/LogoutButton.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,cAAA;AAAR;AAEQ;EACI,eAAA;AAAZ","sourcesContent":[".link {\n    font-weight: 500;\n    font-size: 14px;\n    color: #161626;\n    width: 215px;\n    padding: 15px;\n    display: flex;\n    align-items: center;\n\n    .linkIcon {\n        margin-right: 10px;\n    }\n\n    &.icon {\n        width: inherit;\n\n        .linkIcon {\n            margin-right: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "LogoutButton_link__49om3",
	"linkIcon": "LogoutButton_linkIcon__1sgfe",
	"icon": "LogoutButton_icon__jxtWu"
};
export default ___CSS_LOADER_EXPORT___;
