import React from 'react'
import styles from './AboutPersona.module.scss'

interface Props {
    craftedBy: string
}

export const AboutPersona: React.FC<Props> = ({ craftedBy }) => {
    return (
        <div className={styles.container}>
            <div
                className={styles.backgroundImage}
                style={{ backgroundImage: 'url("/images/PDFTemplate/SecondTemplatePage/aboutBackground.svg")' }}
            />

            <div className={styles.leftSide}>
                <div className={styles.title}>
                    <span>About</span>
                    <img src="/images/PDFTemplate/SecondTemplatePage/logoWhite.svg" alt="" />
                </div>

                <p className={styles.text}>
                    Persona.ly is a mobile-first DSP operating worldwide. Using our proprietary bidder and
                    machine-learning algorithms, we offer transparent, performance-driven, highly-targeted UA and
                    retargeting solutions at scale with access to over 1.8 million ad auctions per second.
                </p>

                <div className={styles.cteator}>
                    <span>Crafted with</span>
                    <img src="/images/PDFTemplate/SecondTemplatePage/redHeart.svg" alt="" />
                    <span>by {craftedBy ? craftedBy : '{Name Last Name}'}</span>
                </div>
            </div>

            <div className={styles.rightSide}>
                <span>Trusted by:</span>
                <img src="/images/PDFTemplate/SecondTemplatePage/trustedBy.svg" alt="" />
            </div>
        </div>
    )
}
