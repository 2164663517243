import React, { useState } from 'react'
import Select from 'react-select'

import { dropDownStyles } from './utils/dropDownStyles'
import { OptionType } from './utils/inputTypes'
import { DraftType } from '../../utils/DraftsContext'
import styles from './Input.module.scss'
import { TextInput } from './TextInput'

interface Props {
    name: keyof DraftType
    placeholder: string
    options: OptionType[]
    value: OptionType
    setValue: (name: keyof DraftType, value: DraftType[keyof DraftType] | null) => void
    title?: string
    manualFieldName?: keyof DraftType
    manualValue?: string
}

export const SingleDropDownInput: React.FC<Props> = (props) => {
    const { name, placeholder, options, value, setValue, title, manualFieldName, manualValue } = props
    const [showManual, setShowManual] = useState(!!manualValue)
    const selectOptions = [...options]

    if (manualFieldName) {
        selectOptions.unshift({ value: 'manual', label: 'Insert Manually' })
    }

    const toggleShowManual = () => {
        setShowManual(!showManual)
        if (manualFieldName && showManual) setValue(manualFieldName, null)
        else setValue(name, null)
    }

    const handleSelect = (selectedOption: any) => {
        selectedOption.value === 'manual' ? toggleShowManual() : setValue(name, selectedOption.value)
    }

    const handleChangeManual = (name: keyof DraftType, value: string) => {
        if (manualFieldName) setValue(name, value)
    }

    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            {manualFieldName && showManual ? (
                <div className={styles.manualInputContainer}>
                    <TextInput
                        name={manualFieldName}
                        placeholder="Insert Company Logo"
                        value={manualValue ?? ''}
                        setValue={handleChangeManual}
                    />
                    <div className={styles.toggleManual} onClick={toggleShowManual} />
                </div>
            ) : (
                <Select
                    defaultValue={value.label ? value : null}
                    options={selectOptions}
                    onChange={handleSelect}
                    placeholder={placeholder}
                    styles={dropDownStyles}
                    maxMenuHeight={200}
                    isClearable={false}
                />
            )}
        </div>
    )
}
