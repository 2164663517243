import React from 'react'
import { CaseStudyType } from '../../../utils/FormInfoContext'
import styles from './CaseStudy.module.scss'

interface Props {
    data: CaseStudyType
}

export const CaseStudy: React.FC<Props> = ({ data }) => {
    const {
        appLogo,
        writerCompanyName,
        testimonial,
        deckContentImage,
        writerName,
        title,
        writerImage,
        resultOne,
        resultTwo,
    } = data

    return (
        <div className={styles.container}>
            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${deckContentImage})` }} />

            <div className={styles.content}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{writerCompanyName}</h2>
                    <img src="/images/PDFTemplate/SecondTemplatePage/caseStudyTag.svg" alt="" />
                </div>

                <div className={styles.main}>
                    {appLogo && <img className={styles.icon} src={appLogo} alt="" />}
                    <div className={styles.description}>
                        <p className={styles.text}>{testimonial}</p>
                        <div className={styles.author}>
                            <img className={styles.authorImage} src={writerImage} alt="" />
                            <span className={styles.name}>
                                {writerName}
                                {title ? `, ${title}` : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.progress}>
                <h3 className={styles.title}>Results</h3>
                <div className={styles.results}>
                    {[resultOne, resultTwo].map(
                        (result) =>
                            result.text && (
                                <div className={styles.result} key={result.text}>
                                    <div className={styles.resultValue}>
                                        <span>{result.number}</span>
                                        <img
                                            src={`/images/PDFTemplate/${
                                                result.arrow === 'up' ? 'growUpBlue' : 'growDownGreen'
                                            }.svg`}
                                            alt=""
                                        />
                                    </div>
                                    <span className={styles.resultName}>{result.text}</span>
                                </div>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
