// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CaseStudiesCard_container__H1LEj {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.CaseStudiesCard_header__jYxGw {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.CaseStudiesCard_header__jYxGw .CaseStudiesCard_title__ZFbcS {\n  font-weight: 600;\n  font-size: 28px;\n  line-height: 120%;\n  letter-spacing: 0.4px;\n  color: #191536;\n}\n.CaseStudiesCard_header__jYxGw .CaseStudiesCard_text__z4Wcr {\n  font-weight: 500;\n  font-size: 13px;\n  line-height: 24px;\n  color: #58586d;\n}\n\n.CaseStudiesCard_cases__ah0eL {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/PDFTemplate/FirstTemplatePage/CaseStudiesCard/CaseStudiesCard.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AAAI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAER;AACI;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAAR;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AADJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n.header {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n\n    .title {\n        font-weight: 600;\n        font-size: 28px;\n        line-height: 120%;\n        letter-spacing: 0.4px;\n        color: #191536;\n    }\n\n    .text {\n        font-weight: 500;\n        font-size: 13px;\n        line-height: 24px;\n\n        color: #58586d;\n    }\n}\n\n.cases {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CaseStudiesCard_container__H1LEj",
	"header": "CaseStudiesCard_header__jYxGw",
	"title": "CaseStudiesCard_title__ZFbcS",
	"text": "CaseStudiesCard_text__z4Wcr",
	"cases": "CaseStudiesCard_cases__ah0eL"
};
export default ___CSS_LOADER_EXPORT___;
