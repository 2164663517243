import React from 'react'
import { ManualSegmentInterface } from '../../../utils/DraftsContext'
import { SegmentType } from '../../../utils/FormInfoContext'
import styles from './Segments.module.scss'

interface Props {
	rtManualSegments: {
		rtManualSegment1: ManualSegmentInterface
		rtManualSegment2: ManualSegmentInterface
		rtManualSegment3: ManualSegmentInterface
	}
	rtSegments: {
		segment1: SegmentType
		segment2: SegmentType
		segment3: SegmentType
	}
	appIcon: string
}

export const Segments: React.FC<Props> = ({ rtSegments, rtManualSegments, appIcon }) => {
	const { segment1, segment2, segment3 } = rtSegments
	const { rtManualSegment1, rtManualSegment2, rtManualSegment3 } = rtManualSegments

	const firstSegmentImage = rtManualSegment1.file.url || segment1.url
	const firstSegmentText = rtManualSegment1.name || segment1.name

	const secondSegmentImage = rtManualSegment2.file.url || segment2.url
	const secondSegmentText = rtManualSegment2.name || segment2.name

	const thirdSegmentImage = rtManualSegment3.file.url || segment3.url
	const thirdSegmentText = rtManualSegment3.name || segment3.name

	return (
		<div className={styles.container}>
			<div className={styles.appIcon}>{appIcon && <img src={appIcon} alt='' />}</div>
			<div className={styles.firstSegment}>
				{firstSegmentImage && <img src={firstSegmentImage} alt='' />}
				<p>{firstSegmentText}</p>
			</div>
			<div className={styles.secondSegment}>
				{secondSegmentImage && <img src={secondSegmentImage} alt='' />}
				<p>{secondSegmentText}</p>
			</div>
			<div className={styles.thirdSegment}>
				{thirdSegmentImage && <img src={thirdSegmentImage} alt='' />}
				<p>{thirdSegmentText}</p>
			</div>
		</div>
	)
}
