import React from 'react'
import { BlurBlock } from '../../BlurBlock'
import { AppVerticalInterface, ImageInterface, ManualSegmentInterface } from '../../utils/DraftsContext'
import { CaseStudyType, SegmentType } from '../../utils/FormInfoContext'
import { AboutPersona } from './AboutPersona'
import { Achievements } from './Achievements'
import { CaseStudy } from './CaseStudy'
import styles from './SecondTemplatePage.module.scss'
import { Segments } from './Segments'

interface Props {
	data: {
		companyLogo: ImageInterface
		craftedBy: string
		manualVertical: string
		appVertical: AppVerticalInterface
		appIcon: string
		rtManualSegments: {
			rtManualSegment1: ManualSegmentInterface
			rtManualSegment2: ManualSegmentInterface
			rtManualSegment3: ManualSegmentInterface
		}
		rtSegments: {
			segment1: SegmentType
			segment2: SegmentType
			segment3: SegmentType
		}
		rtCaseStudy: CaseStudyType
	}
}

export const SecondTemplatePage: React.FC<Props> = ({ data }) => {
	const { craftedBy, manualVertical, appVertical, appIcon, rtManualSegments, rtSegments, rtCaseStudy, companyLogo } =
		data

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img src="/images/logo.svg" alt="" />
				<img src="/images/PDFTemplate/crossIcon.svg" alt="" />
				<img src={companyLogo.url} alt="" />
			</div>

			<div className={styles.main}>
				<div className={styles.leftSide}>
					<div className={styles.reEngageExistingApp}>
						<div className={styles.content}>
							<h2 className={styles.title}>
								Re-engage Your Existing App Users at the right place, time and price.
							</h2>
							<p className={styles.text}>
								<span className={styles.bold}>
									Machine learning-driven programmatic retargeting solution.
								</span>{' '}
								Using our proprietary <span className={styles.blue}>Dynamic Audiences</span>{' '}
								segmentation engine, Persona.ly's DSP can define user segments based on their in-app
								behavior to drive desired ROAS.
							</p>
							<p className={styles.text}>
								By slicing and dicing the in-app activity data, Persona.ly identifies and tests various
								audience segments, detects the users with the most potential to be re-engaged, and
								displays the creatives that are right for them.
							</p>
						</div>

						<img
							className={styles.reEngageImage}
							src="/images/PDFTemplate/SecondTemplatePage/reEngageExistingApp.svg"
							alt=""
						/>
					</div>

					<div className={styles.performingSegments}>
						<h2 className={styles.title}>
							Best-Performing Segments for{' '}
							{manualVertical ? manualVertical : appVertical ? appVertical.name : '{VERTICAL}'} Apps:
						</h2>
						<Segments rtSegments={rtSegments} rtManualSegments={rtManualSegments} appIcon={appIcon} />
					</div>

					<CaseStudy data={rtCaseStudy} />
				</div>

				<div className={styles.rightSide}>
					<div className={styles.utilizingMachineLearning}>
						<div className={styles.content}>
							<h2 className={styles.title}>Utilizing Machine Learning</h2>
							<p className={styles.text}>
								A proprietary machine learning algorithm is at the core of our programmatic DSP.
								Processing over 4,000,000 ad auctions a second while precisely targeting the audience,
								our platform accurately predicts the value of each impression and which users are going
								to interact with an app.
							</p>
							<p className={styles.text}>
								It then bids accordingly, allowing us to achieve deep-funnel event KPIs. Various
								classification models and data enrichment enable reliable classification, including age
								and gender prediction.
							</p>
						</div>

						<img
							className={styles.utilizingImage}
							src="/images/PDFTemplate/SecondTemplatePage/utilizingMachineLearning.svg"
							alt=""
						/>
					</div>

					<Achievements />

					<AboutPersona craftedBy={craftedBy} />
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.social}>
					<img src="/images/PDFTemplate/globalIcon.svg" alt="" /> persona.ly
				</div>

				<div className={styles.social}>
					<img src="/images/PDFTemplate/emailIcon.svg" alt="" /> info@persona.ly
				</div>
			</div>

			<BlurBlock
				top={5}
				left={5}
				background="linear-gradient(180deg, rgba(206, 156, 255, 0.3) 0%, rgba(69, 224, 252, 0.3) 100%)"
				transform="matrix(0, -1, -1, 0, 0, 0)"
				opacity={1}
			/>

			<BlurBlock
				top={40}
				right={20}
				background="linear-gradient(180deg, #FF6262 0%, #FBB251 100%)"
				opacity={0.2}
			/>

			<BlurBlock
				bottom={35}
				left={45}
				background="linear-gradient(180deg, #00D4F9 0%, #1FEAAA 100%)"
				opacity={0.1}
			/>
		</div>
	)
}
