import React, { useState } from 'react'
import { AppSearch } from '../../components/AppSearch'
import { DraftsPreviewList } from '../../components/DraftsPreviewList'
import styles from './Drafts.module.scss'

export const Drafts: React.FC = () => {
    const [search, setSearch] = useState('')

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <AppSearch value={search} setValue={setSearch} />
            </div>

            <div className={styles.main}>
                <DraftsPreviewList searchName={search} />
            </div>
        </div>
    )
}
