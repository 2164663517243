import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { navBarConfiguration } from '../utils/navBarConfiguration'
import styles from './NavBar.module.scss'

interface Props {
    isIcon: boolean
}

export const NavBar: React.FC<Props> = ({ isIcon }) => {
    const { pathname } = useLocation()

    return (
        <>
            {navBarConfiguration.map(({ name, url, image }) => {
                const isActive = pathname.includes(url)

                return (
                    <Link
                        className={`${styles.link} ${isIcon ? styles.icon : ''} ${isActive ? styles.active : ''}`}
                        to={url}
                        key={name}
                    >
                        <img className={styles.linkIcon} src={image} alt="link" />
                        {isIcon ? '' : name}
                    </Link>
                )
            })}
        </>
    )
}
